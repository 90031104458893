<template>
  <div>
    <b-input v-model="email" type="email" placeholder="email"/>
    <b-input v-model="password" type="password" placeholder="password"/>
    <b-button @click="login">Login</b-button>
    <span @click="$bvModal.show('reset')">reset</span>
    <b-modal id="reset" title="reset password" hide-footer>
      <b-input v-model="resetEmail" type="email" placeholder="email"/>
      <b-button @click="reset">Reset</b-button>
    </b-modal>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/Footer'
  export default {
    name: 'Login',
    components: {
      Footer,
    },
    data() {
      return {
        email: '',
        password: '',
        resetEmail: '',
      };
    },
    methods: {
      login() {
        this.axios
        .post("auth/login", {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          if (res.status !== 200) return;
          localStorage.setItem("token", res.data.token);
          this.$store.state.loggedIn = true;
          this.$store.state.preName = res.data.preName;
          this.$store.state.lastName = res.data.lastName;
          this.$router.push("/");
        })
        .catch((/*err*/) => {
        });
      },
      reset() {
        this.axios
          .post("auth/requestPasswordReset", {
            email: this.resetEmail,
          })
          .then(() => {
            // this.$bvModal.close('reset');
          })
          .catch((/*err*/) => {
          });
      },
    },
  }
</script>

<style scoped>

</style>